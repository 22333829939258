.comment-button-dropdown-list {
  display: none;
  position: absolute;
  background: white;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--gray-6);
  box-sizing: border-box;
  box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 5;
  /* left: 0; */
}

.comment-button,
.comment-button-small,
.comment-button-medium {
  /* Gray 6 */
  border: 1px solid var(--gray-5);
  box-sizing: border-box;
  border-radius: 4px;
  background: transparent;
  /* width: 155px; */
  /* height: 32px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  white-space: nowrap;
  transition: border 0.3s;
}

.comment-button:hover,
.comment-button-small:hover,
.comment-button-medium:hover {
  transition: border 0.3s;
  border: 1px solid #e9eaea;
  /* filter: none; */
}

.comment-button p,
.comment-button-small p,
.comment-button-medium p {
  margin: 0 9px 0 9px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 117% */
  letter-spacing: -0.04em;
  /* Gray 2 */
  color: var(--gray-2);
}

.comment-button-small,
.comment-button-medium {
  justify-content: center;
}

.comment-button-small {
  padding: 8px;
  color: var(--gray-2);
}

.comment-button-small p {
  margin: 0;
  font-size: 12px;
  line-height: 12px;
}

.comment-button-medium {
  width: 88px;
  height: 32px;
}

.comment-button-dropdown-list hr {
  border-top: 1px solid var(--gray-6);
  margin: 4px 0px 4px 0px;
  width: 100%;
}

.comment-button-dropdown-list label,
.comment-button-dropdown-list p {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 117% */
  letter-spacing: -0.04em;
  /* Gray 2 */
  color: var(--gray-2);
  display: flex;
  padding: 4px 30px 4px 8px;
}

.comment-button-dropdown-list label input {
  margin-right: 12px;
}

.comment-button-dropdown:hover .comment-button-dropdown-list,
.comment-button-dropdown-list:hover {
  display: flex;
}

.button-dropdown-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 30px;
  margin: 0;
}

.button-dropdown-item:hover {
  filter: opacity(0.8);
  background: var(--gray-6);
}
