#container_2 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.navbar {
  display: flex;
  width: 100%;
  height: 64px;
  padding: 16px 24px;

  position: fixed;
  top: 0px;
  left: 0px;
  justify-content: space-between;
}

.navbar_mobile {
  display: flex;
  width: 100%;
  height: 50px;
  background-color: white;
  padding: 9px;

  position: fixed;
  top: 0;
  left: 0;
}

.nav-btn {
  font-family: "Inter";
  letter-spacing: -0.04em;
}

.upperBtn {
  width: 82px;
  height: 26px;
  margin-left: 28px;
  display: block;
}

.sliderLabel {
  font: Inter;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
}

#upper {
  float: left;
  margin-left: 15px;
  color: #8a8f98;
}

#lower {
  float: right;
  margin-right: 15px;
  color: #8a8f98;
}

.frontBtn {
  width: 82px;
  height: 40px;
  margin-left: 28px;
  margin-top: 2px;
  display: block;
}

.backBtn {
  width: 82px;
  height: 40px;
  margin-left: 28px;
  margin-top: 2px;
  display: block;
}

.lowerBtn {
  width: 82px;
  height: 26px;
  margin-left: 28px;
  margin-top: 2px;
  display: block;
}

.rightBtn {
  width: 26px;
  height: 82px;
  margin-top: -110px;
  float: left;
  display: block;
}

.leftBtn {
  width: 26px;
  height: 82px;
  margin-top: -110px;
  float: right;
  margin-right: 8px;
  display: block;
}

.optionButton {
  height: 25px;
  font-size: 12px;
  font-weight: 600;
  position: relative;
  background-color: white;
}

/* .movements_table {
  border-radius: 10px 0px 0px 10px !important;
  width: 247px !important;
  height: 286px !important;
} */

.polygon {
  fill: white;
}

.rotateRight {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.rotateLeft {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.text {
  text-anchor: middle;
  fill: black;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 18px;
}

.avatar-img {
  min-width: 32px;
  min-height: 32px;
}

.patient-profile-link {
  color: black;
  margin: 5px;
}
