.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  width: 100%;
  height: 100%;
  font-family: "Inter" !important;
}

/* custom navbar(avatar, 3 buttons at top) css */
.nav-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.avatar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar-img {
  max-width: 32px;
  max-height: 32px;
  border-radius: 50%;
  border: 2px solid #cfcfcf;
}
.avatar-wrapper span {
  font-size: 16px;
  font-weight: bold;
  margin: 0.7em;
}
.nav-select {
  height: 34px;
  padding: 10px 24px;
  cursor: pointer;
  font-size: 16px;
  background: #fafafa;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  color: #444444;
  font-weight: normal;
  line-height: 16px;
  outline: none;
}
.nav-btn {
  width: 135px;
}

.nav-btn-mobile {
  position: absolute;
  top: 9px;
  right: 9px;
  width: 55px;
}
.nav-icon {
  font-size: 9px;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid black;
  margin-right: 10px;
  align-self: center;
}

#home-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
div.section {
  margin-top: 30px;
  width: 50%;
  background-color: rgba(black, 0.1);
  padding: 32px;
}

@media screen and (max-width: 768px) {
  .nav-links {
    list-style-type: none;
    overflow: hidden;
    transition: "all .2s ease";
    text-align: center;
    background: #cfcfcf;
    font-size: 20px;
    opacity: 0.5;
  }

  .nav-links-ul {
    list-style-type: none;
    font-weight: normal;
    font-size: 16px;
  }

  .nav-links-li {
    font-weight: bold;
    padding: 5px;
  }
}
#canvas-root {
  overflow: hidden;
}

#canvas-root {
  overflow: hidden;
}
/* custom movements card(in the middle right) css */
