@import "~antd/dist/antd.css";
/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
} */

/*
.ant-drawer-body {
  padding: 0
}

.ant-menu-submenu-title {
  font-weight: bold
} */

:root {
  --purple-1: #7723f5;
  --purple-2: #f9f4ff;
  --red-1: #ff3a60;
  --red-2: #fff5f7;
  --pink-1: #fc4c81;
  --pink-2: #fff6f8;
  --yellow-1: #fcbc0c;
  /* --yellow-1: #ffd852; */
  --yellow-2: #fffdf5;
  --orange-1: #ffa100;
  --orange-2: #fffaf1;
  /* New colors */
  --gray-1: #010102;
  --gray-2: #46454d;
  --gray-3: #8a8f98;
  --gray-4: #a6abae;
  --gray-5: #e9eaea;
  --gray-6: #f4f4f4;
  --gray-10: #f3f3f3;
  --white: #ffffff;
  --alert-100: #ff3b30;
  --alert-25: #ffccc9;
  --teal-100: #5ac8fa;
  --teal-25: #cff2ff;
  --purple-100: #5856d6;
  --purple-25: #d6d3f7;
  --pink-100: #ff5474;
  /* Old yellow */
  /* --warning-100: #ffcc00; */
  --warning-100: #f9c642;
  --warning-25: #fff3b8;
  --success-100: #34c759;
  --success-25: #c3f3d2;
  --orange-100: #ff9500;
  --orange-25: #ffe4ba;
  --primary: #0076ff;
  --primary-2: #e4f1ff;
  --turquoise-1: #03fccf;
}

.patient-details-comment {
  background: #fbfbfb;
  margin-bottom: 16px;
  padding: 0 10px;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 8px;
}

.dark {
  color: #1a1a1a;
  word-break: break-word;
  overflow-wrap: break-word;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: inherit !important;
  /* border-right-width: 0px !important; */
  box-shadow: none;
}

.ant-input-search .ant-input:focus-visible {
  border-color: inherit !important;
  box-shadow: none;
}

.ant-input-search:hover {
  border-color: none !important;
  box-shadow: none !important;
}

.ant-input-search .ant-input:hover {
  /* border-right-width: 0px !important; */
  background: #f3f3f3;
}

.note-notification-content {
  border-radius: 4px;
  border: 1px solid var(--gray-6);
  word-break: break-word;
}

.note-notification-reply-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  background-color: #fbfbfb;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  margin: 16px 0px;
  padding: 12px 8px 8px 8px;
}

.note-notification-reply-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.note-notification-reply-content > textarea {
  padding: 0;
}
/* Override ant-d input */

.note-notification-reply-content textarea,
.note-notification-reply-content textarea:focus,
.note-notification-reply-content textarea:hover {
  border: none;
  box-shadow: none;
  resize: none;
}

.note-notification-reply-content textarea::-webkit-scrollbar {
  display: none;
}

.note-notification-reply-content textarea {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.note-notification-content p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */
  letter-spacing: -0.04em;
  /* Gray 3 */
  color: var(--gray-3);
  margin: 10px 8px 10px 8px;
}

.comment-button-dropdown-list {
  display: none;
  position: absolute;
  background: white;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--gray-6);
  box-sizing: border-box;
  box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 5;
  /* left: 0; */
}

.comment-button,
.comment-button-small,
.comment-button-medium {
  /* Gray 6 */
  border: 1px solid var(--gray-6);
  box-sizing: border-box;
  border-radius: 4px;
  background: transparent;
  width: 155px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  white-space: nowrap;
}

.comment-button:hover,
.comment-button-small:hover,
.comment-button-medium:hover {
  filter: opacity(0.5);
}

.comment-button p,
.comment-button-small p,
.comment-button-medium p {
  margin: 0 9px 0 9px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 117% */
  letter-spacing: -0.04em;
  /* Gray 2 */
  color: var(--gray-2);
}

.comment-button-small,
.comment-button-medium {
  justify-content: center;
}

.comment-button-small {
  width: 70px;
  height: 28px;
}

.comment-button-small p {
  margin: 0;
}

.comment-button-medium {
  width: 88px;
  height: 32px;
}

comment-button-dropdown-list hr {
  border-top: 1px solid var(--gray-6);
  margin: 4px 0px 4px 0px;
  width: 100%;
  display: block;
  margin-bottom: 8px;
}

.comment-button-dropdown-list > label {
  margin: 0 32px 8px 8px;
}

.comment-button-dropdown-list > label,
.comment-button-dropdown-list > p {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 117% */
  letter-spacing: -0.04em;
  /* Gray 2 */
  color: var(--gray-2);
  display: flex;
  /* margin: 4px 30px 4px 8px; */
}

.comment-button-dropdown-list > label > input {
  margin-right: 8px;
}

.comment-button-dropdown:hover .comment-button-dropdown-list,
.comment-button-dropdown-list:hover {
  /* display: block; */
  display: flex;
}

.avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 10px;
  line-height: 12px;
  background: grey;
  display: flex;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.line-break {
  border-top: 1px solid var(--gray-6);
  margin: 0;
}

.ant-input-search input {
  background: transparent;
}

.ant-comment-inner {
  align-items: center;
  padding: 5px 0;
}

#root {
  width: 100%;
  height: 100%;
  margin-top: 0;
  z-index: 1;
  /* text-align: center; */
}

.logo {
  color: #161d2d;
  letter-spacing: 0.2px;
  font-size: 18px;
  line-height: 72px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  text-decoration: none !important;
  background: #fff;
  height: 100%;
}

.patient-table-list {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */

.patient-table-list::-webkit-scrollbar {
  display: none;
}

.logo:active {
  color: #161d2d;
}

.logo:hover {
  color: #161d2d;
}

.logo::before {
  content: "ArchForm";
}

.ant-layout-header {
  height: 72px;
  line-height: 72px;
}

.ant-table-tbody {
  cursor: pointer;
}

.patient-image-modal .ant-modal-content {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.patient-image-modal {
  top: 0px !important;
}

.patientchatmessage:hover {
  background: #f9f8fa;
  border-radius: 10px;
}

.teeth-checkbox {
  display: flex;
}

.teeth-checkbox-item {
  margin: 0 1px 0 1px;
  min-width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  outline: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  color: #999999;
}

.teeth-checkbox-selected {
  background: var(--gray-6);
  color: #0d0d0d;
  border: 1px solid #0d0d0d;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.chat-msg-image {
  max-width: 200px;
  max-height: 100px;
  object-fit: contain;
}

.teeth-checkbox .ant-divider-horizontal {
  margin: 8px 0 8px 0;
  position: inherit;
}

.teeth-checkbox-horizontal-divider {
  margin: 8px 0 8px 0;
  height: 1px;
  width: 100%;
  background: #e8e8e8;
}

.teeth-checkbox-vertical-divider {
  width: 1px;
  margin: 0px 8px;
  background: #e8e8e8;
}

/* Patient table (list page) */

/* .patient-table-avatar {
    height: 24px;
    width: 24px;
    margin-right: 8px;
}

.patient-table-avatar > img {
  object-fit: cover;
  border-radius: 50px;
  width: 100%;
  height: 100%;
}
.th {
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.04em;
  color: #8A8F98;
  height: 16px;
}

.td {
  cursor: pointer;
  justify-content: start;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  letter-spacing: -0.04em;
  color: #46454D;
}

.tr .td:last-child {
  justify-content: flex-end;
  right: 0;
  height: 100%;
}

.tr .td:first-child {
  cursor: default;
}

.tr {
  border-top: 1px solid var(--gray-10) ;
  border-bottom: 1px solid var(--gray-10) ;
  padding: 16px 24px;
}

.table > div:first-child .tr {
  margin: 30px 0px 0px 0px;
  border: none;
  border-bottom: 1px solid var(--gray-10);
}

.patient-table .th {
  background: #fff !important;
}

.patient-file-list .ant-list-item-meta-title {
  margin-bottom: 0px !important;
} */

@media (min-width: 481px) {
  .signup-form {
    /* position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); */
    min-width: 350px;
    border: none;
    /* margin-right: 20%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .signup-form .ant-card-body {
    width: 100%;
  }
  .signup-cta {
    font-family: Roboto, "sans-serif";
    color: #161d2d;
    letter-spacing: 0.2px;
    /* margin-left: 15%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    font-size: 18px;
  }
  #signup-cta-calendar {
    margin-left: 2%;
  }
  #signup-content {
    /* background: '#fff!important'; */
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  #calendly-inline-widget {
    width: 1000px;
  }
  #calendar {
    /* margin-right: 5% !important; */
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .signup-form .ant-card-body {
    width: 100%;
  }
  .signup-cta {
    text-align: left;
    font-size: 15px;
    font-family: Roboto, "sans-serif";
    color: #161d2d;
    letter-spacing: 0.2px;
    margin: 0.2em 0.5em 0.2em 0.5em;
  }
  #calendly-inline-widget {
    width: 100%;
    /* margin-left: 0; */
  }
  #signup-content {
    flex-direction: column;
  }
}

.signup-input-label {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #161d2d;
  letter-spacing: 0.2px;
  text-decoration: none;
  line-height: 24px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
}

#signup-content .ant-form-item-control {
  line-height: 24px !important;
}

.login-form {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-width: 350px;
}

.header {
  background: #fff;
  box-shadow: 0 1px 0 0 #e6e8eb;
  z-index: 1;
  margin-bottom: 24px;
}

.header .ant-row {
  height: 100%;
}

.header .ant-col {
  height: 100%;
}

thead .ant-table-selection {
  display: none;
}

.active-product {
  box-shadow: #808080 0px 6px 9px;
  border: 0.1rem inset rgb(0, 118, 255);
}

.active-product:hover {
  box-shadow: #808080 0px 6px 9px !important;
  border: 0.1rem inset rgb(0, 118, 255);
}

#signup_cc {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 10px;
  line-height: 1.5;
  padding: 9px 11px;
  /* box-shadow: 0 0 black; */
}

.slick-slide {
  height: 1px;
}

.slick-slide {
  height: 1px !important;
}

.slick-active {
  height: 100% !important;
}

.dropzonecarddisabled {
  opacity: 0.3;
}

.ant-carousel .slick-initialized .slick-slide {
  text-align: center;
}

.ant-upload-list-item-info span {
  height: 100%;
}

.ant-upload-list-item-name {
  white-space: normal;
  max-width: 200px;
}

.ant-avatar img {
  object-fit: cover;
}

.calendly-spinner {
  position: absolute;
  top: 0px;
}

.Purple-1 {
  background: var(--purple-1);
  border-radius: 3px;
}

.Purple-2 {
  background: #f9f4ff;
  border-radius: 3px;
}

/* Red 1 */

.Red-1 {
  background: #ff3a60;
  border-radius: 3px;
}

.Red-2 {
  background: #fff5f7;
  border-radius: 3px;
}

/* Pink 1 */

.Pink-1 {
  background: #fc4c81;
  border-radius: 3px;
}

.Pink-2 {
  background: #fff6f8;
  border-radius: 3px;
}

.Yellow-1 {
  background: #ffd852;
  border-radius: 3px;
}

.Yellow-2 {
  background: #fffdf5;
  border-radius: 3px;
}

.Orange-1 {
  background: #ffa100;
  border-radius: 3px;
}

.Orange-2 {
  background: #fffaf1;
  border-radius: 3px;
}

.Dark {
  background: #1a1a1a;
}

.flex,
.flex-row,
.flex-column {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

/* Media  */

/* .ant-layout {
  background: #fff;
}
.ant-menu-inline {
  border-right: none;
}

.ant-layout-sider-zero-width-trigger {
  top: 0;
  background: #fff;
  color: #161d2d;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}

.ant-layout-sider-zero-width-trigger:hover {
  background: #fff;

}
th {
  background: #fff!important;
  color: #757A80!important;
}

td {
  font-weight: 600;
  font-size: 16px;
  color: #121925!important;
} */

/* Text  */

.nav-link-bar {
  height: 36px;
  line-height: 36px;
  background: #58a3ff;
  /* border-radius: 6px; */
  text-align: center;
  color: white;
  font-weight: 500;
  position: fixed;
  z-index: 10;
  width: 100%;
}

.nav-link-bar .content {
}

.nav-link-bar a {
  color: white;
  text-decoration: underline;
  text-underline-offset: 4px;
  font-weight: 500;
}

.nav-link-bar a:hover {
  color: #0076ff;
}
