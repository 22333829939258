.login-body {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-tips {
  text-align: center;
  font-weight: 500;
  word-wrap: break-word;
  margin-top: 16px;
}
.login-tips span {
  color: #374151;
}

.login-tips a {
  color: #0076ff;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.login-container {
  border-radius: 10px;
  box-shadow: 1px 3px 20px 0px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  display: flex;
  padding: 56px;
  flex-direction: column;
  height: auto;
}

.login-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px;
  text-align: center;
  letter-spacing: -0.03em;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.form-field {
  padding: 8px;
  border: 1px solid #f2f2f2;
  background-color: #fbfbfb;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 16px;
  width: 256px;
  border-radius: 4px;
}

.form-field::placeholder {
  color: #8a8f98;
}

.login-submit-button {
  padding: 8px;
  width: 256px;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--primary-2);
  color: var(--primary);
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3;
  border: 1px solid transparent;
}

.login-submit-button:hover {
  background-color: transparent;
  border: 1px solid var(--primary);
  transition: all 0.3s;
}

.login-links-group {
  display: flex;
  justify-content: space-between;
}

.login-link {
  color: var(--primary);
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.ant-alert-error {
  margin-top: 16px;
}
