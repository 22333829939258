.change-password-submit-button {
  display: block;
}

.settings-page-container .shipping-address-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #1a1a1a;
  margin-bottom: 24px;
}
