.file-notification-content p {
  /* Caption/Extra small/500 */
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 117% */
  letter-spacing: -0.04em;
  /* Gray 4 */
  color: var(--gray-4);
}

.notification-header-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 117% */
  letter-spacing: -0.04em;
  /* Gray 1 */
  color: #010102;
}

#notifications-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 10% 0 10%;
}

#notifications-outer-container {
  width: 100%;
  height: 100vh;
}

.notification-container {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  z-index: 2;
}

.notification-content {
  /* box-shadow: 1px 0px 60px 0px rgba(0, 0, 0, 0.1); */
  width: 75%;
  margin: 0 2em 0 2em;
}

#notifications-shadow {
  box-shadow: 1px 3px 60px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 75%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.notification-actions {
  width: 25%;
  margin: 1em 0 0 2em;
}

.notification-actions button {
  margin-top: 8px;
}

#notifications-navbar {
  /* width: 100%; */
  height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 24px;
}

#notifications-navbar input {
  background: var(--gray-6);
}

.notifications-header-filter-link {
  cursor: pointer;
  /* Caption/Small/500 */
  margin: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */
  letter-spacing: -0.04em;
  /* Gray 4 */
  color: var(--gray-4);
}

.notifications-header-filter-link-active {
  color: #010102;
}

.notification-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 117% */
  letter-spacing: -0.04em;
  /* Dark */
  color: var(--gray-4);
}

.notification-timestamp {
  margin: 0;
}

.notification-inner-content {
  margin: 16px 0px 16px 32px;
}

.status-notification-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status-name-notification-box {
  height: 22px;
  width: 104px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.04em;
  text-align: center;
}

.status-name-notification-box p {
  margin: 0px !important;
  color: white;
}

.inbox-search {
  background: var(--gray-6);
  width: 60%;
  margin-bottom: 0px;
}

@media (max-width: 1400px) {
  .inbox-search {
    width: 30%;
  }
}
