#canvas-root {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}

.label {
  color: #fff;
  font-family: sans-serif;
  padding: 2px;
  background: rgba(0, 0, 0, 0.6);
}
