.sidebar {
  width: 256px;
  height: calc(100vh - 36px);
  background: #fafafa;
  display: flex;
  flex-direction: column;
  font-family: Inter;
  position: fixed;
  padding: 24px 16px;
  z-index: 2;
}

.sidebar-container {
  scroll-behavior: smooth;
}

@media only screen and (max-height: 865px) {
  .sidebar {
    overflow-y: hidden;
  }
  .sidebar:hover {
    overflow-y: overlay;
  }

  .sidebar::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  .sidebar::-webkit-scrollbar-thumb {
    background: #999999;
    border-radius: 16px;
  }
}

.sidebar-top-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
  margin-left: 6px;
}

.sidebar-top-nav > h4 {
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  color: var(--gray-2);
  margin-bottom: 0px;
}

.sidebar-top-nav > img {
  width: 100px;
  cursor: pointer;
}

.sidebar-letter-icon {
  background-color: #da8e8e;
  color: var(--white);
  font-size: 10px;
  border-radius: 4px;
  margin-right: 8px;
}

.sidebar-menu,
.sidebar-bottom-items {
  display: flex;
  flex-direction: column;
}

.sidebar-bottom-items {
  margin-top: auto;
  padding-top: 32px;
}

.sidebar-menu-title {
  color: var(--gray-1);
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  margin-top: 8px;
}

.sidebar-menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
  padding: 6px;
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: transparent;
}

.sidebar-menu-item:hover {
  background-color: var(--gray-5);
  transition: all 0.2s;
}

.sidebar-menu-item-text {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray-2);
}

.sidebar-menu-item-icon,
.sidebar-patient-menu-item-icon {
  height: 14px;
  width: 14px;
  margin-right: 8px;
}

.sidebar-patient-menu-item-icon {
  border-radius: 50%;
  border: 2px solid;
}

.sidebar-menu-item-count {
  order: 2;
  margin-left: auto;
  text-align: right;
  font-size: 14px;
  line-height: 16px;
}

.subscription-prompt-container {
  position: fixed;
  margin-left: 256px;
  width: calc(100vw - 256px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.subscription-prompt-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  align-items: center;
  backdrop-filter: blur(4px);
  z-index: 2;
  overflow: hidden;
}

.subscription-prompt-card {
  width: 500px;
  height: 130px;
  background-color: white;
  border-radius: 4px;
  padding: 16px;
  z-index: 3;
  box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.subscription-prompt-card > div:first-of-type > p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: #8a8f98;
  margin-bottom: 8px;
}

.subscription-prompt-card > div:first-of-type > p:first-of-type {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #1b1c1e;
  letter-spacing: -0.04em;
}

.subscription-prompt-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  padding: 4px 16px;
  width: 120px;
  height: 32px;
  background-color: var(--primary-2);
  border-radius: 2px;
  align-self: center;
}

.subscription-prompt-button:focus {
  outline: none;
}

.subscription-prompt-button p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.04em;
  color: #0076ff;
  margin: 0;
}
