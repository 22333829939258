.patient-table-treatment-tag-container * {
  margin: 0px 2px;
}

.patient-list-table-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.patient-list-table-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Table code  */

.th {
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.04em;
  color: #8a8f98;
  height: 16px;
}

.td {
  cursor: pointer;
  justify-content: start;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  letter-spacing: -0.04em;
  color: #46454d;
}

.tr .td:last-child {
  justify-content: flex-end;
  right: 0;
  height: 100%;
}

.tr .td:first-child {
  cursor: default;
}

.tr {
  /* border-top: 1px solid var(--gray-10) ; */
  border-bottom: 1px solid var(--gray-10);
  padding: 12px 16px;
}

.table-row {
  display: flex;
  border-bottom: 1px solid var(--gray-10);
  padding: 12px 16px;
}

.table > div:first-child .tr {
  margin: 30px 0px 0px 0px;
  border: none;
  border-bottom: 1px solid var(--gray-10);
}

.patient-table .th {
  background: #fff !important;
}

.patient-file-list .ant-list-item-meta-title {
  margin-bottom: 0px !important;
}

.patient-list-body {
  margin-left: 256px;
  min-height: 280px;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

.patient-list-header {
  display: flex;
  align-items: center;
  height: 64px;
  padding: 16px 24px 0px 24px;
  box-sizing: border-box;
}

.patient-list-header-status-icon {
  height: 16px;
  width: 16px;
  margin-right: 8px;
  border-radius: 50%;
  border: 2px solid;
}

.patient-list-header-info {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  white-space: nowrap;
}

.patient-list-header-info p {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.04em;
  margin: 0;
  color: #46454d;
}

.patient-list-search-container {
  flex-grow: 2;
}
.patient-list-header-search {
  width: 100%;
  display: flex;
  padding: 6px 8px;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 4px;
  border: 1px solid var(--gray-5);
}

.patient-list-header-search input {
  border: none;
  background-color: transparent;
  margin: 0px 0px 0px 4px;
  padding: 0;
  color: #1a1a1a;
  box-shadow: none;
  width: 100%;
  font-size: 14px;
  line-height: 12px;
  font-weight: 400;
}

.patient-list-header-search input::placeholder {
  color: #8a8f98;
}

.patient-list-header-search input:focus {
  outline: none;
}

.patient-list-button {
  justify-content: center;
  border: 1px solid #5856d6;
  margin-left: 16px;
  padding: 8px 16px;
  background: transparent;
  box-shadow: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
}

.patient-list-button:hover {
  opacity: 0.5;
  transition: opacity 0.3s;
}

.patient-list-header-actions {
  display: flex;
  align-items: center;
  margin-left: 16px;
  width: 100%;
}

.patient-list-table-container {
  max-width: 100%;
  height: 90vh;
}

.patient-list-table-header {
}

.patient-table-col-1 {
  display: flex;
  word-break: break-word;
  align-items: center;
}

.patient-table-avatar {
  margin-right: 8px;
  height: 24px;
  width: 24px;
}

.patient-table-avatar > img {
  object-fit: cover;
  border-radius: 50px;
  width: 100%;
  height: 100%;
}

.patient-list-patient-name {
  color: #46454d;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}

.days-in-status {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.04em;
  color: #46454d;
}
