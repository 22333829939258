.change-address-button {
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3;
  border: 1px solid transparent;
  width: -webkit-fill-available;
}

.address-container {
  width: 400px;
}

.address-text {
  margin-bottom: 8px;
}

.change-address-button {
  background-color: var(--primary-2);
  color: var(--primary);
}

.address-line {
  padding: 0;
  margin: 0;
}
