.RO_root{
    font-family: initial;
    font-style: normal;
    letter-spacing: -0.04em;
    color: #2F3032;
    font-weight: 500;

}
.RO_modal .ant-modal-content{
    border-radius: 8px;
}
.RO_headline{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 26px;
}
.RO_p1{
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    margin-top: 26px;
}
.RO_p2{
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 500;
}
.RO_p3{
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.04em;
    color: #999999;
    margin-top: 8px;
    margin-left: 16px;
    margin-bottom: 0;
}
.RO_AS{
    display: flex;
    justify-content: space-between;
}

.RO_AS .file-upload-field {
    box-shadow: none;
}
.RO_AS button{
    background-color: #FFF;
    border: 1px dashed #CCCCCC;
    border-radius: 10px;
    width: 160px;
    height: 120px;
    padding: 14px 17px;
    cursor: pointer;
}
.RO_d1{
    width: 192px;

    display: inline-block;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
}
.RO_root input{
    width: 416px;
}
.RO_textArea {
    margin-bottom: 12px;
}

.RO_textArea .ant-input:hover{
    border-color: #0076FF;
}
.RO_textArea .ant-input:focus, .RO_textArea .ant-input-focused{
    border-color: #0076FF;
}
.RO_textArea textarea.ant-input{
    background-color: #F9F9FA;
}
.RO_button{
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
}
.RO_check .ant-checkbox-group-item{
    margin-right: 40px;
}
.RO_check .ant-checkbox-input{
    background-color: #d9d9d9 !important;
}
.RO_button .ant-btn:hover, .RO_button .ant-btn:focus{
    color: #0076FF;
    border-color: #0076FF;
}
.RO_button button.ant-btn.ant-btn-default{
    border-radius: 4px;
    height: 32px;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.04em;
    color: #8A8F98;
}
