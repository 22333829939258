.tag {
  height: 20px;
  text-align: center;
  background: #f4f4f4;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.tag span {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: 0px 8px;
  color: blue;
}
