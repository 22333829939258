/* Settings page CSS */

.settings-layout-container {
  margin-left: 256px;
  padding: 32px 24px;
}

.settings-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px;
  letter-spacing: -0.03em;
}

.settings-subtitle {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #1a1a1a;
  margin-bottom: 24px;
}

.settings-row {
  margin-bottom: 32px;
}

.form-field {
  padding: 8px;
  border: 1px solid #f2f2f2;
  background-color: #fbfbfb;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 16px;
  /* width: 256px; */
  border-radius: 4px;
  width: -webkit-fill-available;
}

.form-field::placeholder {
  color: #8a8f98;
}

.change-password-submit-button,
.logout-button {
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3;
  border: 1px solid transparent;
  width: -webkit-fill-available;
}

.change-password-submit-button {
  background-color: var(--primary-2);
  color: var(--primary);
}

.change-password-loading-button {
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid transparent;
  width: -webkit-fill-available;
  height: 39px;
  line-height: 39px;
  background-color: var(--primary-2);
  color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.logout-button {
  background-color: var(--primary);
  color: var(--primary-2);
}

.login-submit-button:hover {
  background-color: transparent;
  border: 1px solid var(--primary);
  transition: all 0.3s;
}

.sidebar-search {
  width: 100%;
  margin-bottom: 8px;
  color: var(--gray-2);
}

.settings-row .product-info-container {
  width: 240px;
  height: 120px;
  margin-right: 20px;
}

.subscription-info p {
  margin-bottom: 4px;
}

.settings-row .product-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.settings-row .product-title-container p {
  margin: 0;
  width: unset;
}

.settings-row .product-expand-button p {
  margin: 0;
}
.settings-row .product-benefits {
  margin-top: 8px;
}

.settings-layout-container .product-selection-container {
  width: calc(240px * 3 + 33px * 2);
}

.create-patient-disabled-overlay {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}

.create-patient-disabled-overlay > div {
  background: #fbfbfb;
  border: 1px solid var(--gray-6);
  box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 350px;
  height: 150px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  color: #0d0d0d;
  padding: 4px 16px;
  position: fixed;
}

.create-patient-disabled-overlay button {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  cursor: pointer;
  border: none;
  padding: 4px 16px;
  width: 123px;
  height: 32px;
  background-color: var(--primary-2);
  border-radius: 4px;
}

.create-patient-disabled-overlay button:focus {
  outline: none;
}
.create-patient-disabled-overlay button p {
  color: var(--primary);
  line-height: 21px;
}
