/* Layout/commonly used page specific  */

/* .patient-view-layout span, 
.patient-view-layout p {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: -0.04em;
  color: #444444;
} */

.patient-view-container,
.patient-details-header {
  box-shadow: 1px 2px 30px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.patient-view-container {
  /* min-height: 716px; */
}

.patient-details-layout {
  margin-left: 256px;
  padding: 24px;
  height: 100vh;
}

/* header */

.patient-details-header-container {
  display: flex;
  height: 100px;
  /* max-height: 20vh; */
  margin-bottom: 24px;
  box-sizing: border-box;
}

.patient-details-header {
  display: flex;
  padding: 18px 24px;
  width: 100%;
  align-items: center;
  /* justify-content: space-between; */
  flex-direction: row;
}

.patient-details-header {
}

.patient-details-avatar {
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #f2f2f2;
  margin-right: 24px;
}

.patient-details-avatar-placeholder {
  height: 64px;
  width: 64px;
  background-color: gray;
  border-radius: 50%;
}

.patient-details-header-info {
  display: flex;
  flex-direction: column;
}

.patient-details-name {
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: -0.04em;
  margin-bottom: 4px;
}

.patient-id {
  margin-bottom: 8px;
  font-size: 12px;
  color: #444;
  font-weight: 500;
}

.patient-details-tag-group {
  display: flex;
  flex-direction: row;
}

.patient-details-nav-top-group {
  display: flex;
  align-items: center;
  margin-left: auto;
  white-space: nowrap;
}

.patient-details-button-group {
  display: flex;
  align-items: center;
  margin-left: auto;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.patient-details-button-group .comment-button {
  justify-content: center;
}

.patient-button-group-text {
  display: flex;
  flex-direction: column;
  margin: 0px 16px;
}

.paperslip-container {
  height: 16px;
}
.comment-file-button {
  cursor: pointer;
  transition: all 0.3s;
}

.comment-file-button:hover {
  opacity: 0.5;
  transition: all 0.3s;
}

.comment-file-name {
  line-height: 16px;
  margin: 0 8px;
  color: var(--gray-2);
}
/* end  */

.note-notification-reply-actions {
  width: -webkit-fill-available;
}

/*  */

.ant-tabs-tabpane {
  padding: 0px 20px 0px 20px;
  height: 100%;
}

.ant-tabs-bar {
  min-height: 5%;
  margin: 0;
}

.ant-tabs-content {
  height: 95%;
}

.ant-tabs-tabpane-active {
  overflow-y: hidden;
}
.ant-tabs-tabpane-active:hover {
  overflow-y: overlay;
}

.ant-tabs-tabpane-active::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.ant-tabs-tabpane-active::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 16px;
}

.bold-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #1a1a1a;
}

.patient-view-top-bar-status {
  margin: 0px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #5856d6;
}

.sidebar-image-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #444444;
}

.composite-img,
.composite-img-end,
.composite-img-placeholder,
.composite-img-placeholder-end,
.xray-img,
.xray-img-placeholder {
  cursor: pointer;
  width: calc(100% / 3);
  border-radius: 16px;
  display: inline-block;
  height: 60px;
  object-fit: cover;
}

.composite-img,
.composite-img-placeholder,
.xray-img-placeholder {
  padding-right: 3px;
}

.composite-img-placeholder,
.composite-img-placeholder-end {
  height: 60px;
  box-sizing: border-box;
}

.xray-img,
.xray-img-placeholder {
  height: 80px;
}

.composite-img-placeholder > div,
.composite-img-placeholder-end > div,
.xray-img-placeholder > div {
  width: 100%;
  height: 100%;
  padding: 0px 8px;
  border-radius: 2px;
  background: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.xray-img,
.xray-img-placeholder {
  width: 50%;
}

.patient-details-sidebar-link {
  color: #999999;
  cursor: pointer;
  line-height: 16px;
  word-break: break-word;
}

.patient-details-sidebar-link:hover {
  filter: opacity(0.5);
}

.patient-details-sidebar-link a {
  color: #999999;
  line-height: 16px;
}

.patient-details-sidebar-link svg {
  margin-right: 8px;
}

textarea {
  background: none !important;
}

.ant-tabs-nav {
  margin: 12px 0px 12px 24px !important;
}

.ant-tabs-tab {
  margin: 0px !important;
  padding: 0px 12px !important;
}

.ant-tabs-tab-active {
  padding: 0px 8px !important;
  margin: 0px 4px !important;
  color: black !important;
}

.ant-tabs-tab-active::before {
  background: #e6e6e6;
  /* background: black; */
  border-radius: 4px;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  opacity: 0.5;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: initial;
  /* font-weight: 500; */
}

.ant-tabs-nav .ant-tabs-tab:hover::before {
  background: #e6e6e6;
  /* background: black; */
  border-radius: 4px;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  opacity: 0.5;
}

.ant-tabs-ink-bar {
  display: none !important;
}

.tab-container {
  margin: 24px;
}

/* Perscription css */

.perscription-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px 64px;
  margin: 24px 0px 32px 0px;
}

.clinical-conditions {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  margin-top: 24px;
  padding-bottom: 60px;
  flex-direction: column;
  grid-gap: 0px 64px;
}

.crowding-500 {
  font-weight: 500;
}

.crowding-gray {
  color: #999;
}

.crowding-table,
.relationship-table,
.relationship-table-two {
  display: flex;
  flex-direction: row;
}

.crowding-table {
  grid-column: 1/1;
  grid-row: 1/3;
}

.relationship-table {
  grid-column: 2/2;
  grid-row: 1/2;
  /* margin-top: 16px; */
}

.relationship-table-two {
  grid-column: 2/2;
  grid-row: 2/3;
}

.crowding-col-1 {
  /* grid-column: 1/1; */
  margin-right: 16px;
}

.crowding-col {
  margin-left: 16px;
}

.crowding-col-2 {
  margin-left: 45px;
}

.teeth-table-container {
  margin-bottom: 32px;
}

.teeth-table {
  margin-top: 24px;
}

/* Text style */
.patient-details-subtitle {
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.patient-menu-icon-container,
.patient-menu-avatar-container {
  width: 24px;
  height: 26px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background: white;
}

.patient-menu-avatar-container {
  height: 32px;
}

.patient-table-comment-button {
  position: absolute;
  right: 16px;
}

.status-selector-dropdown {
  position: relative;
  border: 1px solid var(--gray-5);
  background-color: #fff;
  width: 155px;
  height: 32px;
  /* padding: 8px; */
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
}

.status-selector-dropdown > p {
  /* width: 100%; */
  line-height: 14px;
  margin: 0px 9px;
}

.status-selector-dropdown p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  font-style: normal;
  color: #444444;
  margin-bottom: 0px;
}

.status-selector-dropdown-list {
  display: none;
  position: absolute;
  background: #fff;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--gray-6);
  box-sizing: border-box;
  box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 5;
  top: 100%;
  left: 0px;
  width: 100%;
  transition: all 0.3s;
  cursor: pointer;
}

.status-selector-dropdown-list p {
  padding: 10px;
  width: 100%;
}

.status-selector-dropdown-list p:hover {
  filter: opacity(0.8);
  background: var(--gray-6);
}

.public-viewer-modal .public-viewer-modal-container {
  display: flex;
  flex-direction: column;
  padding-top: 6px;
}

.public-viewer-modal .public-viewer-modal-row {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.public-viewer-modal-container > p {
  margin-bottom: 8px;
}

.public-viewer-modal a {
  width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.file-description-label {
  margin: 0;
}

@media (max-width: 1000px) {
  .patient-details-header {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .patient-details-button-group {
    margin-left: 0px;
  }

  .patient-button-group-text {
    margin-left: 0px;
  }

}

.perscription-item > p:first-child {
  margin-bottom: 14px;
}

.clinical-conditions div p {
  margin-bottom: 14px;
}