.create-patient-container h1 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin-bottom: 32px;
}

.create-patient-container h2 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.create-patient-container h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 0px;
}

.create-patient-container h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #444444;
}

.create-patient-container p {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #999999;
  margin-bottom: 0px;
}

.form-field {
  color: #0d0d0d;
}

.create-patient-container .uppercase-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #000000;
  white-space: nowrap;
}

.file-upload-field {
  width: 192px;
  height: 219px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 60px rgba(40, 40, 40, 0.08);
  border-radius: 10px;
  cursor: pointer;
}

.file-upload-field .uppercase-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #000000;
  white-space: nowrap;
}

.file-upload-field p {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #999999;
  margin-bottom: 0px;
}

.file-upload-field:focus {
  outline: none;
}

.file-upload-field div {
  width: 160px;
  height: 128px;
  border: 1px dashed #cccccc;
  box-sizing: border-box;
  border-radius: 10px;
}

.file-upload-preview-image,
.file-upload-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.file-upload-preview-image {
  padding: 15px;
}

.file-upload-required-input,
.hidden-input {
  display: flex !important;
  opacity: 0;
  height: 1px;
  margin: -4px;
  cursor: pointer;
}

.hidden-input {
  cursor: pointer;
}

.create-patient-container {
  margin-left: 256px;
  padding: 32px 0px 32px 64px;
}

.basic-info-container {
  width: 536px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 16px;
}

.basic-info-container input {
  width: 256px;
}

.scans-upload-container {
  width: 416px;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 64px;
}

.additional-info-container {
  width: 536px;
  margin-top: 32px;
  margin-bottom: 64px;
}

.treatment-timeline-container {
  display: flex;
  justify-content: space-between;
}

.additional-info-container h3 {
  margin-bottom: 16px;
}

.additional-info-container input {
  width: 256px;
  margin-bottom: 32px;
}

.patient-photos-container {
  width: 640px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 24px;
}

.patient-photos-container .file-upload-field {
  margin-bottom: 32px;
}

.full-composite-upload-field {
  width: 192px;
  height: 219px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;
  border: 1px dashed #cccccc;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 16px;
  cursor: pointer;
}

.full-composite-upload-field h4 {
  color: #444444;
}

.xray-photos-container {
  width: 416px;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 64px;
}

.notes-input {
  width: 640px;
  height: 203px;
  background: #ffffff;
  box-shadow: 0px 2px 60px rgba(40, 40, 40, 0.08);
  border-radius: 10px;
  border: none;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #999999;
  padding: 16px;
  margin-top: 24px;
  margin-bottom: 32px;
}

.notes-input::placeholder {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #999999;
}

.clinical-preferences-container {
  margin-top: 24px;
  margin-bottom: 8px;
  width: 500px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-right: -256px;
}

.clinical-preferences-input {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  margin-bottom: 24px;
}

.clinical-preferences-input p {
  margin-bottom: 16px;
}

.clinical-preferences-input .dropdown-input {
  width: 192px;
  margin-bottom: 0px;
}

.clinical-conditions-container-crowding,
.clinical-conditions-container-ap-relationship {
  display: inline-flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 24px;
  margin-right: 84px;
  margin-bottom: 32px;
}

.clinical-conditions-container-crowding h4,
.clinical-conditions-container-ap-relationship h4 {
  margin-bottom: 0px;
}

.clinical-conditions-container-crowding .dropdown-input,
.clinical-conditions-container-ap-relationship .dropdown-input {
  margin-right: 18px;
  margin-top: 16px;
  margin-bottom: 0px;
  height: 36px;
}

.clinical-conditions-container-crowding {
  width: 434px;
}

.clinical-conditions-container-crowding * {
  flex-basis: 33%;
}

.clinical-conditions-container-crowding .dropdown-input {
  max-width: 124px;
}

.clinical-conditions-container-ap-relationship {
  width: 417px;
}

.clinical-conditions-container-ap-relationship .dropdown-input {
  max-width: 110px;
}

.clinical-conditions-container-ap-relationship * {
  flex-basis: 31%;
}

.clinical-conditions-container-ap-relationship *:nth-child(3n + 4),
.clinical-conditions-container-ap-relationship *:first-child {
  flex-basis: 38%;
}

.clinical-conditions-divider-crowding,
.clinical-conditions-divider-ap-relationship {
  background: #f2f2f2;
  width: 1px;
  position: absolute;
}

.clinical-conditions-divider-crowding {
  left: calc(66% - 11px);
  height: 244px;
  top: 32px;
}

.clinical-conditions-divider-ap-relationship {
  left: calc(69% - 11px);
  height: 88px;
  top: 32px;
}

.teeth-table {
  margin-bottom: 32px;
}

.dropdown-input {
  position: relative;
  padding: 8px;
  border: 1px solid #f2f2f2;
  background-color: #fbfbfb;
  width: 200px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
}

.dropdown-input p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  font-style: normal;
  color: #444444;
  margin-bottom: 0px;
  user-select: none;
}

.dropdown-input-list {
  display: none;
  position: absolute;
  background: #fbfbfb;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--gray-6);
  box-sizing: border-box;
  box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 5;
  top: 100%;
  left: 0px;
  width: 100%;
  transition: all 0.3s;
  cursor: pointer;
}

.dropdown-input-list p {
  padding: 10px;
  width: 100%;
}

.dropdown-input-list p:hover {
  filter: opacity(0.8);
  background: var(--gray-6);
}

.submit-patient-button {
  /* float: right;
  margin-top: -64px; */
}

.submit-patient-button,
.submit-patient-disabled-button {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  padding: 4px 16px;
  width: 123px;
  height: 32px;
  background-color: var(--primary-2);
  border-radius: 4px;
}

.submit-patient-button:focus,
.submit-patient-disabled-button:focus {
  outline: none;
}

.submit-patient-button p,
.submit-patient-disabled-button p {
  color: var(--primary);
  line-height: 21px;
}

.submit-patient-overlay,
.submit-patient-disabled-overlay {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}

.submit-patient-overlay {
  backdrop-filter: blur(5px);
}

.submit-patient-disabled-overlay > div {
  background: #fbfbfb;
  border: 1px solid var(--gray-6);
  box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 350px;
  height: 150px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  color: #0d0d0d;
  padding: 4px 16px;
  position: fixed;
}

.create-patient-container .ant-spin-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: var(--gray-2);
  margin-top: 16px;
}

.create-patient-container .product-selection-subheader {
  margin-top: -16px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.04em;
  color: #8a8f98;
}

.product-selection-container {
  width: calc(330px * 3 + 33px * 2);
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 32px;
}

.product-info-container {
  width: 330px;
  height: 154px;
  background: #f9f9fa;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-info-container .production-selection-description {
  margin-bottom: 16px;
  margin-top: 16px;
  line-height: 21px;
  color: #1b1c1e;
  width: 204px;
}

.product-expand-button,
.product-select-button,
.product-selected-button,
.product-expanded-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  padding: 4px 16px;
  width: 87px;
  height: 32px;
  background-color: var(--primary-2);
  border-radius: 2px;
}

.product-expand-button:focus,
.product-select-button:focus,
.product-selected-button:focus,
.product-expanded-button:focus {
  outline: none;
}

.product-expand-button p,
.product-select-button p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.04em;
  color: #0076ff;
}

.product-expanded-button p,
.product-selected-button p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.04em;
  color: #ffffff;
}

.product-expanded-button,
.product-selected-button {
  background: #0076ff;
}

.product-info-container .product-title-name {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #1b1c1e;
  letter-spacing: -0.04em;
}

.product-expanded-selection-container > div > h3 {
  margin-top: 32px;
}

.product-expanded-selection-container {
  display: flex;
  flex-direction: row;
}

.product-expanded-selection-container > div:first-of-type {
  margin-right: 64px;
}

.product-expanded-selection-container > div > p {
  /* margin-bottom: 24px; */
  margin-top: 8px;
}

.product-info-price-container {
  display: flex;
  justify-content: space-between;
}

.product-info-price-container > p:first-of-type {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: #8a8f98;
  margin: 0px;
}

.product-info-price-container > div:first-of-type > p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  text-align: right;
  letter-spacing: -0.04em;
  color: #1b1c1e;
  margin: 0px;
}

.product-info-price-container > div:first-of-type > p:first-of-type {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 8px;
}

.product-info-price-container > div:first-of-type > p:nth-of-type(2) {
  font-size: 12px;
  line-height: 12px;
  color: #2f3032;
}

.product-in-office-print-container {
  background: #f9f9fa;
  border-radius: 2px;
  width: 191px;
  height: 102px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-in-office-print-container p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.04em;
  color: #1b1c1e;
  margin: 0px 8px 0px 8px;
}

.product-in-office-print-container svg {
  cursor: pointer;
}

/* .product-expanded-container .product-in-office-print-description {
  margin-top: 32px;
  margin-bottom: 24px;
} */

.product-form-aligners-info-container {
  width: 191px;
  height: 102px;
  padding: 16px 12px 12px 12px;
  margin-right: 12px;
}

.product-expanded-form-aligner-selection-container {
  flex-direction: column;
}

.product-selection-form-aligner-stages {
  flex-direction: row;
  display: flex;
  margin-top: 24px;
}

/* Product */

.create-patient-container .product-card-container {
  display: flex;
  flex-direction: row;
  width: calc(330px * 3 + 33px * 2);
  justify-content: space-between;
  position: relative;
}

.create-patient-container .product-card {
  width: 330px;
  height: 205px;
  background: #f9f9fa;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
}

/* For product name/price on same line */
.create-patient-container .product-card > div:first-of-type {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.create-patient-container .product-card p,
.product-expanded-container p {
  font-style: normal;
}

.create-patient-container .product-card-product-name {
  font-weight: 600;
  font-size: 16px;
  max-width: 200px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: #2f3032;
}

.create-patient-container .product-card-product-description {
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.04em;
  color: #2f3032;
}

.create-patient-container .product-card-product-description-sub {
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.04em;
  color: #8a8f98;
  white-space: nowrap;
}

.create-patient-container .product-card-product-price,
.create-patient-container .product-card-product-price-summary {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: right;
  letter-spacing: -0.04em;
  color: #1b1c1e;
}

.create-patient-container .product-card-product-price-summary {
  color: #000000;
  text-align: left;
}

.create-patient-container .product-card-product-price-description,
.create-patient-container .product-card-product-price-description-summary {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  letter-spacing: -0.04em;
  color: #8a8f98;
}
.create-patient-container .product-card-product-price-description-summary {
  color: #000000;
  text-align: left;
}

.product-expanded-container {
  background: #ffffff;
  border: 1px solid #dcdde0;
  box-sizing: border-box;
  border-radius: 2px;
  width: 1056px;
  padding: 24px;
  margin-bottom: 32px;
  margin-top: 48px;
}

.product-expanded-container p {
  user-select: none;
}

.product-expanded-container .product-expanded-title {
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: -0.04em;
  color: #2f3032;
}

.product-expanded-container .product-expanded-close-button {
  border: 1px solid #f3f4f5;
  box-sizing: border-box;
  border-radius: 2px;
  background: #ffffff;
}

.product-expanded-container .product-expanded-close-button p {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.04em;
  color: #8a8f98;
  user-select: none;
}

/* For expanded product name and close button on same line */
.product-expanded-container > div:first-of-type {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.product-expanded-container .product-expanded-product-name {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: #2f3032;
  margin-bottom: 8px;
}

.product-expanded-container .product-expanded-product-description {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.04em;
  color: #8a8f98;
  margin-bottom: 8px;
}

.product-expanded-container .product-expanded-partial-manufacturing-selection {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
}

.product-expanded-partial-manufacturing-selection
  .product-form-aligners-info-container {
  margin-right: 36px;
}

.product-expanded-container .product-selection-self-manufacture-selector {
  border: 1px solid #dcdde0;
  box-sizing: border-box;
  border-radius: 2px;
  width: 83px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  margin-top: 18px;
}

.product-expanded-container .product-selection-self-manufacture-selector p {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.04em;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #8a8f98;
  user-select: none;
}

.product-expanded-container .product-selection-self-manufacture-selector svg {
  cursor: pointer;
}

.create-patient-container .product-category-title {
  margin-top: 48px;
  margin-bottom: 42px;
}

.create-patient-container .product-category-title > p {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 125%;
  letter-spacing: -0.04em;
  color: #1b1c1e;
}

.product-category-divider-label {
  display: flex;
  width: 600px;
  justify-content: space-between;
  margin-left: 32px;
}

.product-card-divider {
  border: 0.6px solid #bcbcbc;
  transform: rotate(90deg);
  transform-origin: 0% 0%;
  width: 205px;
  height: 0;
  left: 346px;
  position: absolute;
}
/* .create-patient-container .product-category-title>p:first-of-type {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: #2f3032;
  margin-bottom: 4px;
} */

.create-patient-container .address-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.04em;
  color: #2f3032;
  margin-bottom: 8px;
}

.create-patient-container .shipping-address-title {
  font-weight: 600;
  font-size: 16px;
  max-width: 200px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: #2f3032;
  margin-top: 48px;
  margin-bottom: 12px;
}

.create-patient-container .change-address-button {
  width: 131px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0px;
}

.patient-window-navbar-container {
  position: fixed;
  /* transform: translateX(-64px); */
  left: 256px;
  background: white;
  padding-left: 41px;
  height: 88px;
  /* width: 100%; */
  top: 36px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 256px);
  flex-wrap: wrap;
  box-sizing: border-box;
}

.patient-window-navbar-left,
.patient-window-navbar-right {
  display: flex;
}

.patient-window-navbar-right {
  width: 250px;
}

.patient-window-navbar-stage-container {
  display: flex;
  flex-direction: row;
  /* height: 40px; */
  width: fit-content;
  /* width: 650px; */
  /* border-radius: 50px; */
  /* border: 1px solid #f3f4f5; */
  justify-content: space-evenly;
  /* box-shadow: 0px 2px 60px rgb(40 40 40 / 8%); */
  /* margin-left: 32px; */
  /* position: fixed; */
  /* z-index: 2; */
  position: relative;
}

.patient-window-navbar-divider {
  height: 1px;
  width: calc(100% + 41px);
  background: #d8d8d8;
  top: 80px;
  margin-left: -41px;
  z-index: 3;
  border: 1px solid #f3f4f5;
  order: 3;
}

.patient-window-navbar-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 16px;
  gap: 8px;
  border-radius: 4px;
  cursor: pointer;
}
.patient-window-navbar-button-white {
  color: #46454d;
  background: white;
  border: 1px solid #f4f4f4;
}
.patient-window-navbar-button-blue {
  background: #0076ff;
  border: none;
  color: #ffffff;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.create-patient-container .patient-window-navbar-step-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #999999;

  margin-top: 80px;
  margin-bottom: 8px;
}

.patient-window-navbar-stage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 400;
  cursor: pointer;
  width: 100px;
  /* margin-right: 46px; */
  z-index: 3;
}

.patient-window-navbar-stage:first-of-type {
  border-radius: 50px 0 0 50px;
}

.patient-window-navbar-stage:last-of-type {
  border-radius: 0 50px 50px 0;
}

.patient-window-navbar-stage > p {
  /* margin-left: 8px; */
  white-space: nowrap;
  margin-top: 10px;
  /* margin-right: 12px; */
}

.patient-window-navbar-circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #8a8f98;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  /* margin-left: 12px; */
}

.patient-window-navbar-stage-complete {
  /* background: #e4f1ff; */
}

.patient-window-navbar-stage-line {
  height: 1px;
  width: calc(100% - 89px);
  background: #dcdde0;
  border: 1px solid #f3f4f5;
  position: absolute;
  top: 10px;
  z-index: 2;
}

.patient-window-navbar-stage-complete > p {
  color: #0076ff;
}

.patient-window-navbar-stage-complete .patient-window-navbar-circle {
  background: #0076ff;
}

.patient-window-navbar-stage-complete .patient-window-navbar-circle > p {
  color: #ffffff;
}

.patient-window-navbar-stage-active p {
  color: #0076ff;
}

.patient-window-navbar-stage-active .patient-window-navbar-circle {
  border-color: #0076ff;
}

/* .patient-window-navbar-button {

  height: 40px;

  border-radius: 50px;
  background: none;
  border: 1px solid #E8E9EA;

  font-size: 16px;
  font-weight: 400;
  line-height: 16px;

  padding: 12px 24px;
  color: #8A8F98;

  box-shadow: 0px 2px 60px rgb(40 40 40 / 8%);
}

.patient-window-navbar-button-blue {
  border-radius: 63px;
  background: #0076FF;
  border: none;
  color: white;

}

.patient-window-navbar-button-active {
  cursor: pointer;
} */

.patient-window-bottom-navbar-container {
  position: relative;
  bottom: 0;
  width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
  position: fixed;
  z-index: 2;
}

.patient-window-bottom-navbar-clear {
  position: fixed;
  background: white;
  height: 72px;
  width: 100%;
  bottom: 0;
  z-index: 1;
}

.patient-window-container .patient-window-stage-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #2f3032;

  margin-bottom: 32px;
}

.patient-summary-product-container .proudct-summary-category-label {
  margin: 6px 0px;

}

.patient-summary-product-container .product-card {
  height: 150px;
}

.patient-summary-product-container 
.product-card-product-price-summary,
.patient-summary-product-container 
.product-card-product-price-description-summary {
  display: none;
}

.product-summary-price-details {
  color: #8a8f98;
  font-size: 15px;
  margin-left: 6px;
}

.patient-summary-info-and-photos-container {
  display: flex;
  flex-direction: row;
}

.patient-summary-info-and-photos-container p:first-of-type {
  margin-bottom: 16px;
  margin-top: 32px;
}

.patient-summary-info-container {
  flex-direction: column;
  display: flex;
}
.patient-summary-info-container > div {
  flex-direction: row;
  display: flex;
  /* width: 450px; */
  justify-content: space-between;
}

.patient-summary-info-field-container {
  flex-direction: column;
  justify-content: flex-start;
  width: 200px;
}

.patient-summary-info-container p:last-child {
  font-weight: 400;
  color: #2f3032;
}

.patient-summary-info-container p:last-child {
  font-weight: 400;
  color: #2f3032;
}

.patient-summary-photos-container {
  flex-direction: row;
  display: flex;
}
.patient-summary-photo-field-container,
.patient-summary-xray-field-container,
.patient-summary-xray-field-container-clear,
.patient-summary-photo-field-container-clear {
  background: var(--gray-6);
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 8px;
  margin-bottom: 8px;
}

.patient-summary-photo-field-container-clear,
.patient-summary-xray-field-container-clear {
  background: none;
}

.patient-summary-photo-field-container,
.patient-summary-photo-field-container-clear {
  width: 84px;
  height: 52px;
}

.patient-summary-photo-field-container-clear img,
.patient-summary-xray-field-container-clear img {
  object-fit: contain;
  height: 100%;
}

.patient-summary-xray-field-container,
.patient-summary-xray-field-container-clear {
  width: 130px;
  height: 83px;
}
.patient-summary-product-container {
  margin: 16px 0px;
}

.patient-summary-product-container > div {
  margin-right: 32px;
}

.patient-summary-edit-button {
  border: 1px solid #0076ff;
  border-radius: 4px;
  cursor: pointer;
}

.patient-summary-edit-button p {
  margin: 3px 13px;
  line-height: 20px;
  color: #0076ff;
}

.patient-summary-section-header {
  margin: 0px 12px 0px 0px;
  font-size: 18px !important;
  line-height: 20px !important;
  letter-spacing: -0.04em;
  color: #2f3032;
}

.create-patient-duplicate-warning {
  margin-top: 100px;
}

.archform-product-card-container {
  display: flex;
  flex-direction: row;
  margin-left: 32px;
  justify-content: space-between;
  width: 696px;
}

@media screen and (max-width: 1260px) {
  .patient-window-navbar-stage-container {
    order: 2;
  }
  .patient-window-navbar-container {
    justify-content: flex-start;
    height: 130px;
  }

  .patient-window-navbar-step-label {
    margin-top: 110px !important;
  }
}

/* .product-expanded-container .product-info-container {
  margin-top: 24px;
}


.product-expand-button,
.product-select-button,
.product-selected-button,
.product-expanded-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  padding: 4px 16px;
  width: 87px;
  height: 32px;
  background-color: var(--primary-2);
  border-radius: 2px;
}

.product-expand-button:focus,
.product-select-button:focus,
.product-selected-button:focus,
.product-expanded-button:focus {
  outline: none;
}

.product-expand-button p,
.product-select-button p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.04em;
  color: #0076ff;
}

.product-expanded-button p,
.product-selected-button p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.04em;
  color: #ffffff;
}

.product-expanded-button,
.product-selected-button {
  background: #0076ff;
} */
